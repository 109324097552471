@font-face{
  src: url(./Assets/fonts/Wavehaus-158ExtraBold.otf);
  font-family: Wavehaus;
}

@font-face{
  src: url(./Assets/fonts/bold.otf);
  font-family: manrope;
  font-weight: bold;
}

@font-face{
  src: url(./Assets/fonts/light.otf);
  font-family: manrope;
  font-weight: lighter;
}

@font-face{
  src: url(./Assets/fonts/regular.otf);
  font-family: manrope;
  font-weight: normal;
}

.logo{
  width: 80px;
}

a,a:hover{
  text-decoration: none;
  color:#fff;
}

html{
  height:100%;
}

body{
  margin: 0;
  font-size: 16px;
  font-family: manrope;
  overflow-x:hidden; 
  color: #2522A6;
}

p{
  font-weight: 300;
}

img {
  max-width: 100%;
}

.navbar-default{
  background-color: transparent !important; 
  border: 0 !important;
  border-radius: 0 !important;
  padding:20px 50px;
}

.dashboard {  
  padding:0 75px;
}

.navbar-brand{
  margin-top: -6px; 
  color: #2522a6 !important; 
  font-weight: bold;
  font-size: 2em;
  font-family: Wavehaus;
}

.navbar-default .navbar-toggle {
  border: 0 !important;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #2522A6;
}

.icon-bar:nth-child(3){
  width: 60%;
}

.icon-bar:nth-child(4){
  width: 70%;
}

.navbar-right>li>.lognav{
  color: #2522A6 !important;
}


.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
  background-color: transparent;
}

.App{
  text-align: center;
  background: url('./Assets/background-img.png');
  height: 100vh;
  background-position: -30% 75%;
  background-color: #f5f5f8;
}

img{
  max-width: 100%;
}

.tagline, .about-site{
  text-align: left;
  color:#2522A6;
}

.tagline{
  font-weight: bolder;
  margin-top:15vh;
}

.hero{
  margin-top:7vh;
}

.about-site{
  width: 100%;
}

.navbar-right>li>a{
  text-align: center;
  color: #fff !important;
  font-weight: bold;
  font-size: 0.9em;
  margin-top: 5px;
  text-transform: uppercase;
}

.download-app{
  text-decoration: none;
  color: #fff !important;
  background-color: #2522A6;
  box-shadow: 5px 5px 25px 0 rgba(46,61,73,.2);
  border-radius: 30px;
  padding: 12px 25px;
  border: none;
}

.arrowdown{
  width: 25px;
}

.navbar-toggle{
  margin-top:12px;
}

/* Dashboard */

.userPic {
  width: 50%;
  border-radius: 50%;
  margin-top: 10px;
}

.image-div > div {
  margin-bottom: 55px;
  height: 500px;
}

.thumbnail {
  border: none;
  box-shadow: 5px 5px 25px 0 rgba(0,0,0,0.07);
  border-radius: 6px;
  padding: 10px 0 !important;
}

.timestamp {
  padding: 10px !important;
  display: flex;
  justify-content: space-between;
}

.timestamp > a:nth-child(2) {
  white-space: nowrap;
}

.welcome {
  font-weight: bold;
  text-align: center;
}

.feature-icon {
  margin: 10vh auto 0;
  width: 100%;
  height: 200px;
}

.feature-link {
  text-align: center;
}

.feature-link > a {
  color: #2522A6 !important;
  margin-left: -15px !important;
  margin-top: -45px !important;
}

.labelled-image {
  width: 75%;
  display: block;
  margin: 25px auto 20px;
  border-radius: 8px;
  box-shadow: 5px 5px 25px 0 rgba(0,0,0,0.2);
}

.labels {
  width: 80%;
  margin: 0 auto 20px;
}

.label {
  background-color: #ececec;
  padding: 8px 5px;
  border-radius: 6px;
  box-shadow: 5px 5px 25px 0 rgba(0,0,0,0.07);
  display: inline-flex;
  color: #2522A6;
  text-align: center;
  font-size: 12px;
  margin-right: 5px; 
  margin-bottom: 5px;
}

.flex-buttons {  
  width: 75%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: space-around;
}

.flex-buttons > i, .flex-buttons > button {
  cursor: pointer;
  margin-right: 5px;
  font-size: 18px;
  border-radius: 6px;
  border: none;
  box-shadow: 5px 5px 25px 0 rgba(0,0,0,0.07);
  color: #Bb5485;
  background-color: transparent;
  outline: none;
}

#dialog-title {
  color: #2522A6;
  font-weight: bold;
  font-size: 1em;
}

.similar {
  color: #fff;
  background-color: #2522A6;
  padding: 8px 12px;
  box-shadow: 5px 5px 25px 0 rgba(0,0,0,0.07);
  border-radius: 8px;
  font-size: 14px;
  margin-right: 10px;
}

.similar:nth-child(even) {
  background-color: #Bb5485;
}

.empty > img {
  margin: 25px auto;
  display: block;
  width: 70%;
}

.text {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  box-shadow: 5px 5px 25px 0 rgba(0,0,0,0.07);
}

@media screen and (max-width: 768px){
  .navbar-default{
    padding: 5px !important;
  }
  .navbar-right>li>a{
    color: #2522a6 !important;
  }
  .tagline, .hero{
    margin-top: 20px;
  }
  h2{
    margin-top:5px !important;
    text-align: center !important;
  }
  .form{
    margin-top: 20px
  }
  .submit{
    display: inline;
    margin: 0 auto;
  }

  .form{
    padding: 30px;
    width: 100%;
  }

  .googleicon{
    margin-top: -1px;
  }

  .form-control{
    width: 100%;
  }
  .App{
    background: none;
    height: 100%;
  }
  p{
    width: 100% !important;
    margin-bottom: 25px;
  }
  .dashboard {
    width: 100%;
    padding: 5px;
  }
  .welcome {
    font-size: 1.5em;
    padding: 0 15px;
    margin-bottom: -25px;
  }
  .feature-icon {
    margin: 25px auto -15px;
  }
  .labelled-image {
    width: 80%;
  }
  .empty > img {
    margin: 5vh auto;
    width: 90%;
    height: 300px;
  }
  body {
    padding-bottom: 25px;
  }
  .image-div > div {
    height: auto;
  }
}

@media screen and (min-width: 769px) and (max-width: 991px){
  .form{
    width: 70%;
  }

  .form-control{
    width: 100%;
  }
}

@media screen and (min-width: 1440px){
  .navbar-default{
    padding: 30px 60px !important;
  }
  .App{
    background-position: -50% 80%;
  }
  .hero{
    margin-top: 18vh;
  }
  body{
    font-size: 18px;
  }
  .tagline{
    margin-top: 21vh;
  }
  img{
    max-width: 110%;
  }
  .form{
    margin-top: 10vh;
  }
}

@media screen and (min-width: 1600px){
  .navbar-default{
    padding: 30px 60px !important;
  }
  .container {
    width: 80%;
  }
  .App{
    background-position: -80% 100%;
  }
  .hero{
    margin-top: 18vh;
  }
  body{
    font-size: 18px;
  }
  .navbar-default{
    padding: 30px 0 !important;
  }
  .tagline{
    margin-top: 25vh;
  }
  img{
    max-width: 110%;
  }
  .form{
    margin-top: 10vh;
  }
}

@media screen and (min-width: 1920px){
  .container{
    width: 80%;
  }
  .navbar-default{
    padding: 30px 0 !important;
  }
  .App{
    background-position: -200% 100%;
    background-repeat: no-repeat;
  }
  .hero{
    margin-top: 18vh;
  }
  body{
    font-size: 21px;
  }
  .tagline{
    margin-top: 23vh;
    font-size: 3em;
  }
  img{
    max-width: 120%;
  }
  .form{
    padding: 100px 75px;
  }
  .googleicon{
    margin-top: -3px;
  }
  .tagline-center{
    font-size: 2em;
  }
  .form-control{
    font-size: 18px;
  }
  .newhere{
    font-size: 16px;
  }
}

@media screen and (min-width: 2560px){
  .container{
    width: 80%;
  }
  .navbar-default{
    padding: 60px 0 !important;
  }
  .navbar-right>li>a{
    color: #17165c !important;
  }
  .App{
    background: none;    
  }
  .download-app{
    padding: 20px 40px;
    border-radius: 40px;
  }
  .hero{
    margin-top: 18vh;
  }
  body{
    font-size: 28px;
  }
  .tagline{
    margin-top: 23vh;
    font-size: 3em;
  }
  img{
    max-width: 120%;
  }
  .googleicon{
    width: 20px;
  }
  .form-control{
    font-size: 21px;
    margin: 40px auto;
  }
  .newhere{
    font-size: 18px;
  }
}




